const $ = require('jquery');

const wrapButton = function wrapButton() {
  $('.wysija-submit').wrap('<div class="button button--icon"></div>');
};

wrapButton();



